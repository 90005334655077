import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';

import {IconModule, IconSetModule, IconSetService} from '@coreui/icons-angular';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';

import {AppComponent} from './app.component';

// Pages
import {TotemFuneralsComponent} from './site/totem-funerals/totem-funerals.component';
import {DefaultLayoutComponent} from './site/account/dashboard/container/default-layout.component';
import {MainLayoutComponent} from './site/account/site/container/main-layout.component';

// Services
import {DataService} from './shared/dataService';
import {RouterModule, Routes} from '@angular/router';
import {HttpService} from './models/http/http.service';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MyHttpInterceptor} from './interceptors/http.interceptor';
import {JwtSettings} from './shared/jwt-settings';
import {AuthService} from './shared/authService';
import {LoaderService} from './shared/loaderService';

// Import 3rd party components
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {ChartsModule} from 'ng2-charts';
import {NgSelect2Module} from 'ng-select2';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import {AccountRoutingModule} from './account.routing';
import {LoginActivate} from './extensions/LoginActivate';
import {MainRoutingModule, routes} from './main.routing';
import {Ng2PageScrollModule} from 'ng2-page-scroll';
import {Ng7BootstrapBreadcrumbModule} from 'ng7-bootstrap-breadcrumb';
import {CommonModule} from "@angular/common";
import {TotemFuneralsRouting} from "./totemfunerals.routing";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatCardModule} from "@angular/material/card";
import {HeaderService} from "./shared/header.service";
import {
  RedeemCodeDialogComponent
} from "./site/account/dashboard/dialogs/dialog-redeem-code/redeem-code-dialog.component";


const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'www.ememories.it'
  },
  palette: {
    popup: {
      background: '#c8d8e5'
    },
    button: {
      background: '#df1f26'
    }
  },
  theme: 'edgeless',
  type: 'opt-out'
};

@NgModule({
  bootstrap: [AppComponent],
  declarations: [MainLayoutComponent, DefaultLayoutComponent, TotemFuneralsComponent, AppComponent,
    RedeemCodeDialogComponent
  ],
  exports: [
    MatInputModule
  ],
  imports: [
    BrowserModule,
    JwtSettings,
    RouterModule.forRoot(routes),
    ToastrModule.forRoot({
      positionClass: 'toast-center-center',
      timeOut: 0,
      autoDismiss: false,
      tapToDismiss: true,
    }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AccountRoutingModule,
    MainRoutingModule,
    TotemFuneralsRouting,
    IconModule,
    IconSetModule.forRoot(),
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    PerfectScrollbarModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    NgSelect2Module,
    DragDropModule,
    Ng2PageScrollModule,
    CommonModule,
    Ng7BootstrapBreadcrumbModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    MatGridListModule,
    MatCardModule
  ],
  providers: [
    DataService,
    AuthService,
    HeaderService,
    HttpService,
    HttpClient,
    LoaderService,
    {provide: HTTP_INTERCEPTORS, useClass: MyHttpInterceptor, multi: true},
    IconSetService,
    LoginActivate]
})
export class AppModule {
}

