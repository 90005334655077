  <div style="height:auto; overflow: hidden;">

  <div *ngIf="funeralSubjectCount <= 2">
    <div class="row">
      <div *ngFor="let item of listFunerals" class="col-6">
        {{ item.manifestUrl }}
          <img style="width: 100%; display:block; margin:auto" [src]="item.manifestUrl" class="tile-image" layout-fill/>
      </div>
    </div>
  </div>
  <div *ngIf="funeralSubjectCount > 2">
    <div class="row" style="height: 49vh; overflow: hidden;">
      <div *ngFor="let item of listFunerals ; let i = index" class="col-6">
        {{ item.manifestUrl }}
          <img *ngIf="i < 2" style="height: 49vh; display:block; margin:auto" [src]="item.manifestUrl" class="tile-image" layout-fill/>
      </div>
    </div>
    <div class="row" style="height: 49vh; overflow: hidden;">
      <div *ngFor="let item of listFunerals; let i = index" class="col-6">
        {{ item.manifestUrl }}
          <img *ngIf="i >= 2" style="height: 49vh; display:block; margin:auto" [src]="item.manifestUrl" class="tile-image" layout-fill/>
      </div>
    </div>
  </div>
</div>
