import {Component, inject, OnInit} from '@angular/core';
import {navItems} from '../../../_nav';
import {AuthService} from '../../../../shared/authService';
import {Router} from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './main-layout.component.html'
})
export class MainLayoutComponent implements OnInit{

  public sidebarMinimized = false;
  public navItems = navItems;

  constructor(public authService: AuthService,
              public router: Router) {
  }

  ngOnInit(): void {
    }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
}
