<head>
  <title>Ememories</title>
  <meta charset="UTF-8">
  <meta http-equiv="Content-type" content="text/html; charset=UTF-8">

  <style>

    table.dataTable tbody td {
      vertical-align: middle;
    }

    table.table-hover tbody tr:hover {
      cursor: pointer;
      background-color: #e0e4eb;
    }

    table.table-hover tbody tr:hover > .sorting_1 {
      cursor: pointer;
      background-color: #e0e4eb;
    }

    button > span {
      pointer-events: none;
      display: inline-block;
      vertical-align: middle;
    }
  </style>
</head>

<body class="bg-white" style="background: white">
<div style="position: relative;" class="bg-white" >
  <router-outlet></router-outlet>
  <div *ngIf="loading" style="width: 100px; position: fixed;  z-index: 1002;left: 50%; transform: translate(-50%, -50%); top: 50%; overflow: auto; margin-top: -50px; margin-left: -50px;">
    <img src="assets/gifs/eloader.gif" style="width: 100px; height: 100px;" title="Ememories"/>
  </div>
</div>
</body>
