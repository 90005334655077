<!--<app-header-->
<!--  [navbarBrandRouterLink]="['#']"-->
<!--  [fixed]="true"-->
<!--  [navbarBrandFull]="{src: 'assets/img/brand/ememories_banner.png', width: 89, height: 40, alt: 'Ememories'}"-->
<!--  [navbarBrandMinimized]="{src: 'assets/img/brand/ememories.png', width: 30, height: 30, alt: 'Ememories'}"-->
<!--  [sidebarToggler]="false"-->
<!--  [asideMenuToggler]="false">-->
<!--  <ul class="nav justify-content-center">-->
<!--    <li class="nav-item">-->
<!--      <a class="nav-link">-->
<!--        <img src="assets/img/brand/ememories_banner.png" height="80px"/>-->
<!--      </a>-->
<!--    </li>-->
<!--  </ul>-->
<!--</app-header>-->
<div class="app-body">
  <!-- Main content -->
  <main class="main">
    <!-- Breadcrumb -->
    <!-- breaking change 'cui-breadcrumb' -->
    <cui-breadcrumb style="margin-bottom: 0px !important;">
      <!-- Breadcrumb Menu-->
      <li class="breadcrumb-menu">
        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
          <a class="btn" routerLink=""><i class="cil-home"></i> &nbsp;Home</a>
          <a class="btn" routerLink="services"><i class="cil-devices"></i> &nbsp;Servizi</a>
          <a class="btn" routerLink="contact"><i class="cil-chat-bubble"></i> &nbsp;Contatti</a>
          <a class="btn" routerLink="login"><i class="cil-user"></i> &nbsp;Accedi</a>
        </div>
      </li>
    </cui-breadcrumb>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
<app-footer class="d-flex justify-content-between">
<!--  <span>Ememories Società Cooperativa</span>-->
<!--  <span>Cod. Fiscale / Partita Iva / Reg.imprese: 02769960358 - R.E.A.: RE 312021 Capitale Sociale € 5.000,00</span>-->
<!--  <span>Copyright © 2021.</span>-->
</app-footer>
