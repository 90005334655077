import {PasswordResetRequestModel} from 'src/app/models/requests/authModels';
import {logging} from 'protractor';
import {LoginRequestModel, RegisterRequestModel} from './../models/requests/authModels';
import {LoginResponseModel, RegisterResponseModel} from './../models/responses/authModels';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import * as Constants from '../environments/constants';
import {map} from 'rxjs/operators';
import {HttpService} from '../models/http/http.service';
import {Router} from '@angular/router';
import {LoaderService} from './loaderService';
import {
  CreateNewDeceasedRequestModel,
  CreateNewEmemoriesRequestModel,
  CreateNewFrtRequestModel, UpsertHeadquarterRequestModel,
  GetDeceasedsRequestModel,
  GetFuneralsRequestModel,
  UploadCustomerLogRequestModel,
  UploadEmemoriesPictureRequestModel,
  UploadFrtManifestRequestModel, PdfToImageRequest, FrtTotemsAssociateRequestModel, GetEmemoriesRequestModel
} from '../models/requests/requestModels';
import {
  BaseEntitySingleResponseModel,
  GetDeceasedResponseModel,
  GetDeceasedsResponseModel, GetEmemoriesResponseModel,
  GetFuneralsResponseModel,
  PrintCondolencesReportResponseModel
} from '../models/requests/responseModels';
import {Deceased} from '../models/entities/Deceased';
import {DeceasedDto} from '../models/dto/DeceasedDto';
import {Select2Item} from '../models/entities/Select2Item';
import {Template} from '../models/entities/Template';
import {Totem} from "../models/entities/Totem";
import {FuneralDto} from "../models/dto/FuneralDto";
import {Ememories} from "../models/entities/Ememories";
import {EmemoriesBalanceDto} from "../models/dto/EmemoriesBalanceDto";


@Injectable()
export class DataService {

  constructor(private httpClient: HttpService,
              public loaderService: LoaderService) {
  }

  public getDeceaseds(req: GetDeceasedsRequestModel): Observable<GetDeceasedsResponseModel> {
    this.loaderService.show();
    return this.httpClient.post(Constants.BASE_URL + Constants.DECEASEDS_GET, req)
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  public getEmemories(req: GetEmemoriesRequestModel): Observable<GetEmemoriesResponseModel> {
    this.loaderService.show();
    return this.httpClient.get(Constants.BASE_URL + Constants.EMEMORIES_GET + "/" + req.page + "/" + req.pageSize)
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  public getDeceased(id: number): Observable<DeceasedDto> {
    this.loaderService.show();
    return this.httpClient.get(Constants.BASE_URL + Constants.DECEASED_GET + id)
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  public getTotemRegions(): Observable<Select2Item[]> {
    this.loaderService.show();
    return this.httpClient.get(Constants.BASE_URL + Constants.TOTEMS_REGIONS_GET)
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  public getTotemCounties(regionId: string): Observable<Select2Item[]> {
    this.loaderService.show();
    return this.httpClient.get(Constants.BASE_URL + Constants.TOTEMS_COUNTIES_GET.replace('{regionId}', regionId))
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  public getTotemCities(countyId: string): Observable<Select2Item[]> {
    this.loaderService.show();
    return this.httpClient.get(Constants.BASE_URL + Constants.TOTEMS_CITIES_GET.replace('{countyId}', countyId))
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  public getTotemFractions(cityId: string): Observable<Select2Item[]> {
    this.loaderService.show();
    return this.httpClient.get(Constants.BASE_URL + Constants.TOTEMS_FRACTIONS_GET.replace('{cityId}', cityId))
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  public getRegions(): Observable<Select2Item[]> {
    this.loaderService.show();
    return this.httpClient.get(Constants.BASE_URL + Constants.REGIONS_GET)
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  public getCounties(regionId: string): Observable<Select2Item[]> {
    this.loaderService.show();
    return this.httpClient.get(Constants.BASE_URL + Constants.COUNTIES_GET.replace('{regionId}', regionId))
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  public getCities(countyId: string): Observable<Select2Item[]> {
    this.loaderService.show();
    return this.httpClient.get(Constants.BASE_URL + Constants.CITIES_GET.replace('{countyId}', countyId))
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  public getFractions(cityId: string): Observable<Select2Item[]> {
    this.loaderService.show();
    return this.httpClient.get(Constants.BASE_URL + Constants.FRACTIONS_GET.replace('{cityId}', cityId))
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  public getTotems(cityId: string): Observable<Totem[]> {
    this.loaderService.show();
    return this.httpClient.get(Constants.BASE_URL + Constants.TOTEMS_GET.replace('{cityId}', cityId))
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  // public getIngredients(request: GetIngredientsRequestModel): Observable<Ingredient[]> {
  //   this.loaderService.show();
  //   return this.httpClient.post(Constants.BASE_URL + Constants.INGREDIENTS_GET, request)
  //     .pipe(map(resp => {
  //       if (resp && resp.success === true) {
  //         this.loaderService.hide();
  //         return resp.data;
  //       } else {
  //         this.loaderService.hide();
  //         throw ({Success: false, Message: resp.Message});
  //       }
  //     }));
  // }

  // public getCategories(): Observable<Category[]> {
  //   this.loaderService.show();
  //   return this.httpClient.get(Constants.BASE_URL + Constants.CATEGORIES_GET)
  //     .pipe(map(resp => {
  //       if (resp && resp.success === true) {
  //         this.loaderService.hide();
  //         return resp.data;
  //       } else {
  //         this.loaderService.hide();
  //         throw ({Success: false, Message: resp.Message});
  //       }
  //     }));
  // }

  // public getSubcategories(categoryId: string): Observable<Subcategory[]> {
  //   this.loaderService.show();
  //   return this.httpClient.get(Constants.BASE_URL + Constants.SUBCATEGORIES_GET.replace('{categoryId}', categoryId))
  //     .pipe(map(resp => {
  //       if (resp && resp.success === true) {
  //         this.loaderService.hide();
  //         return resp.data;
  //       } else {
  //         this.loaderService.hide();
  //         throw ({Success: false, Message: resp.Message});
  //       }
  //     }));
  // }

  public login(req: LoginRequestModel): Observable<LoginResponseModel> {
    this.loaderService.show();
    return this.httpClient.post(Constants.BASE_URL + Constants.LOGIN, req)
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  public convertPdfToImage(req: PdfToImageRequest): Observable<string> {
    this.loaderService.show();
    return this.httpClient.post(Constants.BASE_URL + Constants.PDF_TO_IMAGE, req)
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  public register(req: RegisterRequestModel): Observable<RegisterResponseModel> {
    this.loaderService.show();
    return this.httpClient.post(Constants.BASE_URL + Constants.REGISTER, req)
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  public passwordReset(req: PasswordResetRequestModel) {
    this.loaderService.show();
    return this.httpClient.post(Constants.BASE_URL + Constants.PASSWORD_RESET, req)
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  public passwordResetRequest(email: string) {
    this.loaderService.show();
    return this.httpClient.post(Constants.BASE_URL + Constants.PASSWORD_RESET_REQUEST, email)
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  createNewDeceased(req: CreateNewDeceasedRequestModel) {
    this.loaderService.show();
    return this.httpClient.post(Constants.BASE_URL + Constants.DECEASED_CREATE, req)
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  getHeadquarters() {
    this.loaderService.show();
    return this.httpClient.get(Constants.BASE_URL + Constants.HEADQUARTERS_GET)
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  createNewHeadquarter(req: UpsertHeadquarterRequestModel) {
    this.loaderService.show();
    return this.httpClient.post(Constants.BASE_URL + Constants.HEADQUARTER_CREATE, req)
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  updateHeadquarter(req: UpsertHeadquarterRequestModel) {
    this.loaderService.show();
    return this.httpClient.post(Constants.BASE_URL + Constants.HEADQUARTER_UPDATE, req)
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  uploadFrtManifest(req: UploadFrtManifestRequestModel) {
    this.loaderService.show();
    return this.httpClient.post(Constants.BASE_URL + Constants.FRT_MANIFEST_UPLOAD, req)
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  funeralTotemAssociate(funeralId: number, totemId: number) {
    this.loaderService.show();
    return this.httpClient.post(Constants.BASE_URL + Constants.FRT_TOTEM_ASSOCIATE.replace('{totemId}', totemId.toString()).replace('{funeralId}', funeralId.toString()))
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  funeralTotemsAssociate(req: FrtTotemsAssociateRequestModel) {
    this.loaderService.show();
    return this.httpClient.post(Constants.BASE_URL + Constants.FRT_TOTEMS_ASSOCIATE, req)
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  createNewFrt(req: CreateNewFrtRequestModel) {
    this.loaderService.show();
    return this.httpClient.post(Constants.BASE_URL + Constants.FRT_CREATE.replace('{deceasedId}', req.deceasedId.toString()), req)
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  uploadEmemoriesPicture(ememoriesId: number, req: UploadEmemoriesPictureRequestModel) {
    this.loaderService.show();
    return this.httpClient.post(Constants.BASE_URL +
      Constants.EMEMORIES_PICTURE_UPLOAD.replace('{ememoriesId}', ememoriesId.toString()), req)
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  createNewEmemories(req: CreateNewEmemoriesRequestModel) {
    this.loaderService.show();
    return this.httpClient.post(Constants.BASE_URL + Constants.EMEMORIES_CREATE.replace('{deceasedId}', req.deceasedId.toString()), req)
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  confirmEmemoriesCreated(ememoriesId: number) {
    this.loaderService.show();
    return this.httpClient.get(Constants.BASE_URL + Constants.EMEMORIES_CONFIRM.replace('{ememoriesId}', ememoriesId.toString()))
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  public getTemplates(): Observable<Template[]> {
    this.loaderService.show();
    return this.httpClient.get(Constants.BASE_URL + Constants.TEMPLATES_GET)
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  getComingFunerals(req: GetFuneralsRequestModel): Observable<GetFuneralsResponseModel> {
    this.loaderService.show();
    return this.httpClient.post(Constants.BASE_URL + Constants.FUNERALS_COMING_GET, req)
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  printCondolencesReport(funeralId: number): Observable<PrintCondolencesReportResponseModel> {
    this.loaderService.show();
    return this.httpClient.post(Constants.BASE_URL +
      Constants.FUNERAL_CONDOLENCES_REPORT_PRINT.replace('{funeralId}', funeralId.toString()), '{}')
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  uploadCustomerLogo(req: UploadCustomerLogRequestModel) {
    this.loaderService.show();
    return this.httpClient.post(Constants.BASE_URL + Constants.CUSTOMER_LOGO_UPLOAD, req)
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  deleteFuneral(deceasedId: number, id: string) {
    this.loaderService.show();
    const url = Constants.BASE_URL + Constants.FRT_DELETE.replace('{deceasedId}', deceasedId.toString()).replace('{funeralId}', id);
    console.log(url);
    return this.httpClient.get(url)
      .pipe(map(resp => {
        if (resp && resp.success === true) {
          this.loaderService.hide();
          return resp.data;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  getTotemFunerals(selectedId: string): Observable<Array<FuneralDto>> {
    this.loaderService.show();
    return this.httpClient.get(Constants.BASE_URL + Constants.TOTEM_FUNERALS_GET.replace("{totemId}", selectedId))
      .pipe(map((resp: any) => {
        if (resp.List) {
          this.loaderService.hide();
          return resp.List;
        } else {
          this.loaderService.hide();
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  getBalanceMe(): Observable<EmemoriesBalanceDto> {
    return this.httpClient.get(Constants.BASE_URL + Constants.BALANCE_ME)
      .pipe(map((resp: any) => {
        if (resp.data) {
          return resp.data;
        } else {
          throw ({Success: false, Message: resp.message});
        }
      }));
  }

  redeemCode(code: string): Observable<BaseEntitySingleResponseModel<EmemoriesBalanceDto>> {
    return this.httpClient.get(Constants.BASE_URL + Constants.REDEEM_CODE + code)
      .pipe(map((resp: any) => {
        return resp;
      }));
  }
}
