import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {P404Component} from './site/error/404.component';
import {P500Component} from './site/error/500.component';
import {MainLayoutComponent} from './site/account/site/container/main-layout.component';

export const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./site/account/site/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'login',
        loadChildren: () => import('./site/account/site/login/login.module').then(m => m.LoginModule)
      },
      {
        path: 'password-reset/:token/:email',
        loadChildren: () => import('./site/account/site/password-reset/password-reset.module').then(m => m.PasswordResetModule)
      },
      {
        path: 'password-reset-request',
        loadChildren: () => import('./site/account/site/password-reset-request/password-reset-request.module')
          .then(m => m.PasswordResetRequestModule)
      },
      {
        path: 'register',
        loadChildren: () => import('./site/account/site/register/register.module').then(m => m.RegisterModule)
      },
      {
        path: 'registration-success',
        loadChildren: () => import('./site/account/site/register-success/register-success.module').then(m => m.RegisterSuccessModule)
      },
      {
        path: 'services',
        loadChildren: () => import('./site/account/site/services/services.module').then(m => m.ServicesModule)
      },
      {
        path: 'contact',
        loadChildren: () => import('./site/account/site/contact/contact.module').then(m => m.ContactModule)
      },
      {
        path: 'privacy-policy',
        loadChildren: () => import('./site/account/site/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
      },

    ]
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})

export class MainRoutingModule {
}
