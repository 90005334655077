export const DEFAULT_PAGE_SIZE = 50;
export const BASE_URL = 'https://ememories-api.azurewebsites.net/';
export const LOGIN = 'api/v1/login';
export const REGISTER = 'api/v1/register';
export const PASSWORD_RESET = 'api/v1/account/password-reset';
export const PASSWORD_RESET_REQUEST = 'api/v1/account/password-reset-request';
export const LOGOUT = 'api/v1/logout';
export const CUSTOMER_LOGO_UPLOAD = 'api/v1/customer/upload-logo';
export const TEMPLATES_GET = 'api/v1/templates';

export const HEADQUARTER_CREATE = 'api/v1/user/headquarters/create';
export const HEADQUARTER_UPDATE = 'api/v1/user/headquarters/update';
export const HEADQUARTERS_GET = 'api/v1/user/headquarters';

export const DECEASED_CREATE = 'api/v1/deceaseds/create';
export const DECEASED_GET = 'api/v1/deceaseds/';
export const DECEASEDS_GET = 'api/v1/deceaseds';
export const EMEMORIES_GET = 'api/v1/ememories/active';
export const BALANCE_ME = 'api/v1/account/balance';
export const REDEEM_CODE = 'api/v1/account/balance/redeem/';

export const TOTEM_FUNERALS_GET = 'api/v1/totems/{totemId}/funerals';
export const FUNERALS_COMING_GET = 'api/v1/funerals/coming';
export const FUNERAL_CONDOLENCES_GET = 'api/v1/funerals/{funeralId}/condolences';

export const FUNERAL_CONDOLENCES_REPORT_PRINT = 'api/v1/funerals/{funeralId}/condolences/print';

export const FRT_CREATE = 'api/v1/deceased/{deceasedId}/funerals/create';
export const FRT_MANIFEST_UPLOAD = 'api/v1/funerals/upload-manifest';
export const FRT_TOTEM_ASSOCIATE = 'api/v1/totems/{totemId}/funeral-new/{funeralId}';
export const FRT_TOTEMS_ASSOCIATE = 'api/v1/totems/funeral-new';
export const FRT_DELETE = 'api/v1/deceased/{deceasedId}/funerals/{funeralId}/delete';

export const EMEMORIES_CREATE = 'api/v1/deceased/{deceasedId}/ememories/create';
export const EMEMORIES_CONFIRM = 'api/v1/ememories/{ememoriesId}/confirm';
export const EMEMORIES_PICTURE_UPLOAD = 'api/v1/ememories/{ememoriesId}/upload-picture';

export const REGIONS_GET = 'api/v1/regions';
export const COUNTIES_GET = 'api/v1/regions/{regionId}/counties';
export const CITIES_GET = 'api/v1/counties/{countyId}/cities';
export const FRACTIONS_GET = 'api/v1/cities/{cityId}/fractions';
export const TOTEMS_REGIONS_GET = 'api/v1/totems/regions';
export const TOTEMS_COUNTIES_GET = 'api/v1/totems/regions/{regionId}/counties';
export const TOTEMS_CITIES_GET = 'api/v1/totems/counties/{countyId}/cities';
export const TOTEMS_FRACTIONS_GET = 'api/v1/totems/cities/{cityId}/fractions';
export const TOTEMS_GET = 'api/v1/totems/city/{cityId}';

export const PDF_TO_IMAGE = 'api/v1/utility/pdf-to-image';

