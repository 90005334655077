import { Injectable, ComponentFactoryResolver, Injector, ApplicationRef, Type } from '@angular/core';
import {ConfirmDialogConfig, DialogConfig, DialogResult} from "../models/dto/Base/DialogModels";

@Injectable({
  providedIn: 'root'
})
export class EDialogService {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) {}

  /**
   * Visualizza un popup di conferma.
   * @param config Configurazione della finestra di conferma.
   */
  public confirmPopup(config: ConfirmDialogConfig) {
    if (window.confirm(config.message)) {
      config.accept(); // Esegue la callback per l'accettazione
    } else if (config.reject) {
      config.reject(); // Esegue la callback per il rifiuto (se definita)
    }
  }

  /**
   * Visualizza un dialog dinamico.
   * @param config Configurazione del dialog.
   */
  public dialog(config: DialogConfig) {
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(config.component)
      .create(this.injector);

    // Passa i dati al componente
    if (config.params) {
      Object.assign(componentRef.instance, config.params);
    }

    // Aggiunge il componente al DOM
    this.appRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as any).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    // Aggiunge logica per chiusura
    const subscription = (componentRef.instance as any).onClose.subscribe((result: DialogResult) => {
      this.handleOnClose(result, config.onSuccess, config.onError, config.onCancel);
      subscription.unsubscribe();
      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
    });
  }

  /**
   * Gestisce la chiusura del dialog.
   */
  private handleOnClose(
    value: DialogResult,
    onSuccess?: Function,
    onError?: Function,
    onCancel?: Function
  ) {
    if (value.result === 'ok' && onSuccess) {
      onSuccess(value);
    } else if (value.result === 'error' && onError) {
      onError();
    } else if (value.result === 'cancel' && onCancel) {
      onCancel();
    }
  }
}
