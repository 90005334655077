import {Component, Inject} from '@angular/core';
import {BaseDialogComponent} from "../../../../_shared/base-dialog.component";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {DataService} from "../../../../../shared/dataService";
import {HeaderService} from "../../../../../shared/header.service";
import {EmemoriesBalanceDto} from "../../../../../models/dto/EmemoriesBalanceDto";
import {BaseEntitySingleResponseModel} from "../../../../../models/requests/responseModels";

@Component({
  selector: 'redeem-code-dialog',
  templateUrl: 'redeem-code-dialog.component.html',
  styleUrls: ['redeem-code-dialog.component.css']
})
export class RedeemCodeDialogComponent {

  public code : string;
  constructor(
      public dialogRef: MatDialogRef<RedeemCodeDialogComponent>,
      private toastr: ToastrService,
      private dataService: DataService,
      private headerService: HeaderService,
      @Inject(MAT_DIALOG_DATA) public data: { message: string }
  ) {}

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  public Redeem() : void {
    if(this.code == null || this.code.trim() == ""){
      this.toastr.error("Indicare il codice da riscattare.");
    }

    this.dataService.redeemCode(this.code).subscribe((result : BaseEntitySingleResponseModel<EmemoriesBalanceDto>) => {
      if(result.success) {
        this.headerService.balance.next(result.data);
        this.dialogRef.close(true);
      } else {
        this.toastr.error(result.message);
      }
    })
  }
}
