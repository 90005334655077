import {Component, OnInit, OnDestroy, Pipe, AfterViewInit} from '@angular/core';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {DataService} from 'src/app/shared/dataService';
import {BehaviorSubject, interval, Observable} from "rxjs";
import {FuneralDto} from "../../models/dto/FuneralDto";

@Component({
  templateUrl: 'totem-funerals.component.html',
  styleUrls: []
})
export class TotemFuneralsComponent implements AfterViewInit, OnInit, OnDestroy {

  public listFunerals: Array<FuneralDto> = new Array<FuneralDto>();
  public colCount = 0;
  public funeralSubjectCount = 0;
  public totemId: string = null;

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.route.paramMap.pipe()
      .subscribe(
        params => {
          this.totemId = params.get('totemId');
          this.getFunerals();
          interval(1000 * 60 * 60 * 3)
            .pipe()
            .subscribe(() => {
              this.getFunerals();
            });
        });
  }

  getFunerals() {
    this.dataService.getTotemFunerals(this.totemId)
      .pipe()
      .subscribe(res => {
        console.log(res);
        this.listFunerals = [...res];
        this.funeralSubjectCount = res.length;
        this.colCount = this.funeralSubjectCount < 4 ? 1 : 2;
      });
  }


  ngOnDestroy() {
  }

  ngAfterViewInit(): void {
  }
}
