import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

// Import Containers
import {DefaultLayoutComponent} from './site/account/dashboard/container/default-layout.component';

import {P404Component} from './site/error/404.component';
import {P500Component} from './site/error/500.component';
import {LoginActivate} from './extensions/LoginActivate';

export const routes: Routes = [
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'account',
    component: DefaultLayoutComponent,
    canActivate: [LoginActivate],
    children: [
      {
        path: 'funerals',
        loadChildren: () => import('./site/account/dashboard/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'templates',
        loadChildren: () => import('./site/account/dashboard/templates/templates.module').then(m => m.TemplatesModule)
      },
      {
        path: 'ememories',
        loadChildren: () => import('./site/account/dashboard/ememories/ememories.module').then(m => m.EmemoriesModule)
      },
      {
        path: 'deceaseds',
        loadChildren: () => import('./site/account/dashboard/deceaseds/deceaseds.module').then(m => m.DeceasedsModule)
      },
      {
        path: 'deceaseds/new',
        loadChildren: () => import('./site/account/dashboard/deceased-new/deceased-new.module').then(m => m.DeceasedNewModule)
      },
      {
        path: 'deceaseds/:id',
        loadChildren: () => import('./site/account/dashboard/deceased-detail/deceased-detail.module').then(m => m.DeceasedDetailModule)
      },
      {
        path: 'deceaseds/:id/frt/new',
        loadChildren: () => import('./site/account/dashboard/funeralrealtime-new/frt-new.module').then(m => m.FrtNewModule)
      },
      {
        path: 'deceaseds/:id/ememories/new',
        loadChildren: () => import('./site/account/dashboard/ememories-new/ememories-new.module').then(m => m.EmemoriesNewModule)
      },
      {
        path: 'headquarters',
        loadChildren: () => import('./site/account/dashboard/headquarters/headquarters.module').then(m => m.HeadquartersModule)
      },
      {
        path: 'headquarters/new',
        loadChildren: () => import('./site/account/dashboard/headquarters-new/headquarters-new.module').then(m => m.HeadquartersNewModule)
      },
      {
        path: 'headquarters/:id',
        loadChildren: () => import('./site/account/dashboard/headquarters-detail/headquarters-detail.module')
          .then(m => m.HeadquartersDetailModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule {
}
