import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

// Import Containers
import {P404Component} from './site/error/404.component';
import {P500Component} from './site/error/500.component';
import {TotemFuneralsComponent} from "./site/totem-funerals/totem-funerals.component";

export const routes: Routes = [
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'totem-funerals/:totemId',
    component: TotemFuneralsComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class TotemFuneralsRouting {
}
