<div class="container flex flex-column" style="justify-content: space-evenly; height: 100%">
  <div class="flex-row">
    <label style="font-size: 30px; font-weight: bold">Riscatta il codice per aumentare il credito</label>
  </div>
  <div class="flex-row">
    <label style="font-style: italic; text-align: center; font-size: 18px">Per poter generare un Ememories occore avere dei crediti disponibili.<br> Non hai un codice? Chiedilo al tuo distributore di fiducia</label>
  </div>

  <div class="flex-row">
    <div class="flex-column">
      <label class="row" style="font-size: 18px; font-weight: bold">Codice Ememories</label>
      <input class="row" style="padding: 5px; font-size: 18px" type="text" [(ngModel)]="code"/>
    </div>
  </div>
  <div class="flex-row">
    <button class="confirm-button" (click)="Redeem()">RISCATTA IL CODICE</button>
  </div>
</div>
