import { JwtModule } from '@auth0/angular-jwt';

// JWT TOKEN HELPER ---------------------------------------------
export function jwtTokenGet() {
  const token = sessionStorage.getItem('isr');
  return token;
}
// JWT TOKEN HELPER ---------------------------------------------

export const JwtSettings = JwtModule.forRoot({
  config: {
    tokenGetter: jwtTokenGet,
    throwNoTokenError: false,                       // Se il tokenGetter non trova il token, non viene lanciato un errore
    allowedDomains: ['ememories.it'], // L'header Authorization viene aggiunto automaticamente
    disallowedRoutes: ['/API/v1/auth/token']       // L'header Authorization non viene aggiunto automaticamente
  }
});
