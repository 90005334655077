export class BaseEntity {
    public id: string;
    public createdBy: string;
    public createdOn: Date;
    public editedBy: string;
    public editedOn: Date;
    public deleted: boolean;
    public deletedBy: string;
    public deletedOn: Date;
}
