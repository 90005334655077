import {BaseEntity} from './BaseEntity';
import {UserHeadquarter} from './UserHeadquarter';

export class AuthenticatedUser extends BaseEntity{
  public id: string;
  public name: string;
  public code: string;
  public email: string;
  public username: string;
  public password: string;
  public fullAddress: string;
  public logoUrl: string;
  public pIva: string;
  public city: string;
  public cityId: number;
  public county: string;
  public countyId: string;
  public region: string;
  public regionId: number;
  public registeredOn: Date;
  public phoneNumber: string;
  public userHeadquarters: UserHeadquarter[];
}
