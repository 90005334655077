import {BehaviorSubject, Subject} from "rxjs";
import {Injectable} from "@angular/core";
import {EmemoriesBalanceDto} from "../models/dto/EmemoriesBalanceDto";

@Injectable()
export class HeaderService {
  public balance = new BehaviorSubject<EmemoriesBalanceDto|null>(null);

  public triggerBalanceRefresh = new Subject<void>();
}
