import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';

import { ToastrService } from 'ngx-toastr';
import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
  constructor(
    private toastr: ToastrService,
    // private authService: AuthService,
    private jwtHelper: JwtHelperService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const clonedReq = request.clone();
    let clonedReq = request.clone({
      setHeaders: {
        'Content-Type': 'application/json'
      }
    });

    const tokenInSession = sessionStorage.getItem('isr');
    if (tokenInSession && tokenInSession !== '') {
      const tokenValue = 'Bearer ' + tokenInSession;
      clonedReq = request.clone({
        setHeaders: {
          Authorization: tokenValue,
          'Content-Type': 'application/json'
        }
      });
    }

    return next
      .handle(clonedReq)
      .pipe(
        tap((ev: HttpEvent<any>) => {
            if (ev instanceof HttpResponse) {
              if (ev.body == null) {
                this.toastr.error('Il server non ha restituito alcuna risposta');
              }
            }
          },
          (response: any) => {
            if (response.status === 401) {
              const token: string = sessionStorage.getItem('isr');

              if (token != null && this.jwtHelper.isTokenExpired(token)) {
                // const model: Auth.RefreshTokenViewModel = new Auth.RefreshTokenViewModel();
                // model.UserId = this.authService.getAuthUserInfo().id;
                // model.RefreshToken = sessionStorage.getItem('rtk');

                // this.authService.refreshAuthToken(model)
                //   .pipe(
                //     takeWhile(res => res.Success),
                //     tap(r => {
                //       if (r.Success) {
                //         this.authService.setAuthToken(r.Data, true);
                //       }
                //     }),
                //     switchMap(() => next.handle(clonedReq.clone()))
                //   )
                //   .subscribe(resp => {
                //     return new Observable((obs) => obs.next(resp));
                //   });
              }
            }
          })
      );
  }
}
