import {AuthenticatedUser} from './../models/entities/AuthenticatedUser';
import {RegisterResponseModel, LoginResponseModel} from './../models/responses/authModels';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {Jwt} from "../models/dto/Jwt";
import {JwtHelperService} from "@auth0/angular-jwt";
import {EmemoriesBalanceDto} from "../models/dto/EmemoriesBalanceDto";

@Injectable()
export class AuthService {

  constructor(private _jwtHelper: JwtHelperService) {
  }

  public IsAgenzia = new BehaviorSubject<boolean>(true);
  public triggerRoleRefresh = new Subject<void>();

  private _decodedJwt = new BehaviorSubject<Jwt | null>(null);

  public LoginUser(authUser: LoginResponseModel) {
    sessionStorage.setItem('isr', authUser.jwtToken);
    sessionStorage.setItem('authUser', JSON.stringify(authUser));

    this._decodedJwt.next(this._jwtHelper.decodeToken<Jwt | null>(authUser.jwtToken));
  }

  public UpdateUser(authUser: AuthenticatedUser) {
    sessionStorage.setItem('authUser', JSON.stringify(authUser));
  }

  public UpdateRoleSubject() {
    debugger;
    console.log(this.IsAgenzia);
    this.IsAgenzia.next(this._decodedJwt.value?.role == "Agenzia");
    console.log(this.IsAgenzia);
  }

  public Logout() {
    sessionStorage.clear();
  }

  public GetUserRole(): string {
    return this._decodedJwt.value?.role;
  }

  public IsAdmin = (): boolean => this._decodedJwt.value?.role == "Admin";
  public IsIntermediario = (): boolean => this._decodedJwt.value?.role == "Intermediario";
  public IsDistributore = (): boolean => this._decodedJwt.value?.role == "Distributore";

  // public IsAgenzia = (): boolean => this._decodedJwt.value?.Role == "Agenzia";


  public IsUserLogged(): boolean {
    const isr = sessionStorage.getItem('isr');
    return isr != null && isr.length > 0;
  }

  public LoggedUser(): AuthenticatedUser {
    let authUser: AuthenticatedUser = new AuthenticatedUser();
    const userJson = sessionStorage.getItem('authUser');
    if (userJson == null || userJson.length === 0) {
      return null;
    }
    authUser = JSON.parse(userJson);
    return authUser;
  }

}
