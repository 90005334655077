import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Http} from './http.namespace';

@Injectable()
export class HttpService {

  constructor(private http: HttpClient) {
  }

  /**
   * @description Gestione chiamate GET
   *
   */
  public get(url: string): Observable<Http.HttpResponse> {
    // console.log('GET: ' + url);
    return this.http.get<Http.HttpResponse>(url);
  }

  /**
   * @description Gestione chiamate POST
   *
   */
  public post(url: string, model: any = null): Observable<Http.HttpResponse> {
    return this.http.post<Http.HttpResponse>(url, JSON.stringify(model));
  }

  /**
   * @description Gestione chiamate POST che effettuano
   * un upload di file
   *
   */
  public postFile(url: string, fileToUpload: File): Observable<Http.HttpResponse> {
    const formData: FormData = new FormData();
    formData.append('File', fileToUpload);

    return this.http.post<Http.HttpResponse>(url, formData);
  }

  /**
   * @description Gestione chiamate PUT
   *
   */
  public put(url: string, id: any, model: any): Observable<Http.HttpResponse> {
    return this.http.put<Http.HttpResponse>(url + '/' + id, JSON.stringify(model));
  }

  /**
   * @description Gestione chiamate DELETE
   *
   */
  public delete(url: string): Observable<Http.HttpResponse> {
    console.log(url);
    return this.http.delete<Http.HttpResponse>(url);
  }
}
